.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--content-width);
  height: var(--navbar-height);
  margin: 0 auto;
}

.navbar__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  gap: var(--space-4);
}

.navbar__logo {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--color-light-0);
}

.navbar__logo:hover {
  color: var(--color-light-0);
}

.navbar__logo img {
  height: 65px;
  max-height: 100%;
}

.navbar__menu {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.navbar__menu-button {
  display: none;
}

.navbar__menu__text {
  margin: 0;
  padding: var(--space-3);
  font-size: var(--fontsize-1);
  color: var(--color-light-0);
  line-height: 1.5;
}

.navbar__menu__text:hover {
  color: var(--color-light-0);
  text-decoration: underline;
}

.navbar__menu__content {
  margin-top: calc(var(--space-4) + 1.3em);
  padding: var(--space-3) 0;
}

.navbar__menu__content__link {
  padding: var(--space-2) var(--space-3);
  color: var(--color-dark-1);
  font-size: var(--fontsize-1);
  white-space: nowrap;
}

.navbar__menu__content__link:hover {
  color: var(--color-dark-1);
  text-decoration: underline;
}

.navbar__toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding: 12px 0;
  box-sizing: border-box;
}

.navbar__action {
  display: flex;
  align-items: center;
}

.navbar__action__text {
  margin: 0;
  padding: calc(var(--space-2) + var(--space-1));
  font-size: var(--fontsize-1);
  color: var(--color-light-0);
  line-height: 1.5;
}

.navbar__action__text:hover {
  color: var(--color-light-0);
}

.navbar__action__content {
  margin-top: calc(var(--space-3) + 1.2em);
  padding: var(--space-3) 0;
}

.navbar__button {
  padding: 0 var(--space-2);
  border: none;
  color: var(--color-light-0);
  background-color: transparent;
}

.navbar__button__icon {
  padding: 0;
  border: none;
  font-size: var(--fontsize-3);
}

.navbar__search {
  display: flex;
  align-items: center;
  max-width: 170px;
  border-radius: 15px;
  background-color: #e4e2e2;
  padding: var(--space-1) var(--space-3);
}

.navbar__search__input {
  flex: 1;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: var(--fontsize-1);
}

.navbar__search__button {
  padding: 0;
  border: none;
  color: var(--color-light-0);
  background-color: transparent;
}

.navbar__search__icon {
  padding: 0;
  color: var(--color-dark-2);
}

.navbar__cart__content {
  min-width: 300px;
  padding: 0 var(--space-3);
  color: var(--color-dark-1);
}

.navbar__cart__action {
  padding-top: var(--space-3);
}

.navbar__cart__button {
  display: flex;
  justify-content: center;
  padding: calc(var(--space-2) + var(--space-1));
  color: var(--color-light-0);
  background-color: var(--color-dark-2);
  border: none;
  border-radius: 3px;
  font-size: var(--fontsize-1);
  text-align: center;
  transition: background-color 0.2s ease-in;
}

.navbar__cart__button:hover {
  color: var(--color-light-0);
  background-color: var(--color-dark-3);
}

.navbar__cart__count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  min-width: 14px;
  padding: var(--space-1);
  background-color: var(--color-primary-1);
  color: var(--color-light-0);
  border-radius: 15px;
  white-space: nowrap;
  font-size: var(--fontsize-0);
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 105px;
  padding: var(--space-3) 0;
  gap: var(--space-3);
  border-bottom: 1px solid var(--color-light-3);
  box-sizing: border-box;
}

.product__image-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 72px;
  font-size: var(--space-5);
  background-color: var(--color-light-2);
  color: var(--color-light-0);
}

.product__image-empty:hover {
  color: var(--color-light-0);
}

.product__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--color-dark-1);
}

.product__info:hover {
  color: var(--color-dark-1);
}

.product__price {
  color: var(--color-primary-1);
}

.product__remove {
  padding: 0;
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  border: none;
  outline: none;
  cursor: pointer;
}

.navbar__drawer {
  display: fixed;
}

@media screen and (max-width: 576px) {
  .navbar__button {
    padding: 0;
  }

  .navbar__menu {
    display: none;
  }

  .navbar__menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--color-light-0);
  }

  .navbar__container {
    padding: 0 var(--space-3);
    gap: var(--space-3);
  }

  .navbar__logo {
    padding: var(--space-2) 0;
    box-sizing: border-box;
  }

  .navbar__logo h1 {
    margin: 0;
    font-size: var(--fontsize-4);
  }

  .navbar__toolbar {
    display: none;
  }
}
