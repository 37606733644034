.navbar__menu {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.navbar__menu__text {
  margin: 0;
  padding: var(--space-3);
  font-size: var(--fontsize-1);
  color: var(--color-light-0);
  line-height: 1.5;
}

.navbar__menu__text--sidebar {
  padding: var(--space-2) var(--space-3);
  color: var(--color-dark-1);
}

.navbar__menu__text:hover {
  color: var(--color-light-0);
  text-decoration: underline;
}

.navbar__menu__text--sidebar:hover {
  color: var(--color-dark-1);
}

.navbar__menu__content {
  margin-top: calc(var(--space-4) + 1.3em);
  padding: var(--space-3) 0;
}

.navbar__menu__content__link {
  padding: var(--space-2) var(--space-3);
  color: var(--color-dark-1);
  font-size: var(--fontsize-1);
  white-space: nowrap;
}

.navbar__menu__content__link:hover {
  color: var(--color-dark-1);
  text-decoration: underline;
}

.navbar__group {
  padding: var(--space-2) var(--space-3);
  margin: var(--space-3) 0 var(--space-2);
  color: var(--color-dark-0);
  background-color: var(--color-light-2);
  border-top: 1px solid var(--color-light-3);
}

.navbar__group__content {
  margin-bottom: var(--space-3);
}

@media screen and (max-width: 576px) {
  .navbar__menu {
    display: none;
  }
}
